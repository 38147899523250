<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <div style="padding-right: 0.3rem;">

            <b-badge variant="success" v-if="fursStatus === 0">
                <feather-icon
                    icon="CheckIcon"
                    size="21"
                />
                {{$t('menu.subcategory.furs')}}
            </b-badge>

            <b-badge variant="warning" v-else-if="fursStatus === 1">
                !
                {{$t('menu.subcategory.furs')}}
            </b-badge>

            <b-badge variant="danger" v-else-if="fursStatus === 2">
                <feather-icon
                    icon="XIcon"
                    size="21"
                />
                {{$t('menu.subcategory.furs')}}
            </b-badge>


        </div>


        <div class="d-flex align-items-center justify-content-center" style="padding-right: 0.3rem; padding-left: 0.3rem;">
            <div @click="openCashRegisterStatus" style="cursor: pointer; padding-right: 0.6rem;">
                <feather-icon
                    icon="SettingsIcon"
                    size="21"
                />
            </div>


        </div>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown ref="dropdown" right toggle-class="d-flex align-items-center dropdown-user-link" class="bell-icon">
                <template #button-content>
                    <div class="d-flex align-items-center">
                        <feather-icon icon="BellIcon" size="20" />
                        <span v-if="$store.getters['messages/getUnreadMessagesCount'] > 0" class="notification-dot">
                            {{ $store.getters['messages/getUnreadMessagesCount'] }}
                        </span>
                    </div>
                </template>
                <b-dropdown-header>
                    <div class="d-flex align-items-center w-100">

                        <div class="w-100" style="font-size: 20px; font-weight: bold; text-align: left;">{{ $t('messages.messages') }}</div>
                        <b-badge :variant="getVariant()" style="height: 20px;" class="ml-4">{{ $t('messages.new_messages') }}
                            {{ $store.getters['messages/getUnreadMessagesCount'] }}</b-badge>
                    </div>
                </b-dropdown-header>
                <div style="height:10px; width: 100%; border-bottom: 1px solid lightgrey;" />
                <div style="max-height: 60vh; overflow-y: auto;">
                    <div v-if="messages">
                        <b-dropdown-item
                            :link-class="[{ 'dropdown_line': index < messages.length - 1 }, { 'unreadMessage': !message.read }]"
                            v-for="(message, index) in messages" :key="index" @click="viewMessage(message.id)">
                            <div class="d-flex  align-items-center">
                                <div class="d-flex justify-content-center align-items-center"
                                     style="margin-right: 5px; border-radius: 14px; height: 28px; width: 28px;"
                                     :style="{ backgroundColor: getPriority(message.priority).colour }">
                                    <feather-icon size="17" style="color: white;"
                                                  :icon="getPriority(message.priority).icon" />
                                </div>
                                <div>
                                    <div style="font-size: 13px; font-weight: bold; height: 16px;">{{ message.title }}</div>
                                    <div class="d-flex justify-content-start align-items-center">
                                        <div class="d-flex" style="font-size: 10px;">{{ getAuthor(message) }}</div>
                                        <div class="" style="margin-left: 5px; margin-right: 5px;">|</div>
                                        <div style="font-size: 10px;" class="d-flex align-items-center">
                                            <feather-icon size="12" style="margin-right: 2px;"
                                                          :icon="getType(message.type).icon" /> {{ getType(message.type).name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-dropdown-item>
                    </div>
                </div>

                <div class="d-flex justify-content-center w-100 mt-2">
                    <b-button block class="mx-2" variant="primary" @click="seeAllMessages">{{ $t('messages.see_all_messages') }}</b-button>
                </div>
            </b-nav-item-dropdown>
            <div class="d-flex align-items-center justify-content-center" >

                <dark-Toggler class="d-block" />

            </div>
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link noPaddingRight" class="dropdown-user" style="padding-left: 0 !important;">
                <template #button-content>
                    <BBadge class="d-none d-sm-flex align-items-center nav-badge" variant="light" pill>
                        <div class="d-sm-flex user-nav test">
                            <p v-if="userData" class="user-name font-weight-bolder mb-0">
                                {{ userData.name || userData.email }}
                            </p>
                        </div>
                        <BAvatar variant="primary" :text="userNameInitials"/>
                    </BBadge>
                    <BAvatar class="d-sm-none" variant="primary" :text="userNameInitials"/>
                </template>
                <b-dropdown-item link-class="d-flex align-items-center" @click="$refs.password_change_modal.showModal()">
                    <feather-icon size="16" icon="KeyIcon" class="mr-50" />
                    <span>{{ $t('password.change_password') }}</span>
                </b-dropdown-item>
                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>{{ $t('login.logout2') }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>

        <ChangePasswordModal ref="password_change_modal" />
        <CashRegisterStatus ref="cashRegisterStatusButton"></CashRegisterStatus>
    </div>
</template>

<script>
    import {
        BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownHeader, BBadge, BButton, BAvatar
    } from 'bootstrap-vue'
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import CashRegisterStatus from '@/views/components/CashRegisterStatus/CashRegisterStatus.vue'
    import ChangePasswordModal from './ChangePasswordModal.vue'
    import * as Sentry from '@sentry/vue'
    import {getPriority, getType, getAuthor} from '@/libs/Messages'

    export default {
        components: {
            CashRegisterStatus,
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownHeader,
            BDropdownItem,
            BAvatar,
            BButton,
            BBadge,
            // Navbar Components
            DarkToggler,
            ChangePasswordModal
        // BDropdownDivider
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => { }
            }
        },
        data() {
            return {
                fursInterval: null,
                fursStatus: 0,
                getPriority,
                getType,
                getAuthor
            }
        },
        computed: {
            messages() {
                return this.$store.getters['messages/getLast5Messages']
            },
            userData() {
                return this.$store.getters['user/userData']
            },
            userNameInitials() {
                if (this.userData) {
                    const name = this.userData.name ?? this.userData.email
                    if (!name || name.length === 0) return ''

                    const names = name.split(' ')
                    let initials = names[0].charAt(0).toUpperCase()

                    if (names.length === 1) initials += names[0].charAt(1).toUpperCase()
                    else if (names.length > 1) initials += names[1].charAt(0).toUpperCase()
                    return initials
                }
                return ''
            }
        },
        methods: {
            async getFursStatus() {
                try {
                    if (this.userData) {
                        const response2 = await this.$http.get('/api/client/v1/furs/status')
                        const status = response2.data.status ?? false

                        if (status) {
                            this.fursStatus = 0
                        } else {
                            this.fursStatus = 2
                        }
                    }
                } catch (err) {
                    Sentry.captureMessage(err)
                }
            },
            seeAllMessages() {
                this.$refs.dropdown.hide(true)
                this.$router.push({ name: 'system_messages' })
            },
            openCashRegisterStatus() {
                this.$refs.cashRegisterStatusButton.open()
            },
            async logout() {
                try {
                    localStorage.removeItem('userData')
                    await this.$store.dispatch('user/setLoginStatus', false)
                    await this.$store.dispatch('user/updateUserData', {})

                    await this.$http.get('/api/client/v1/logout/')
                    await this.$router.push('/login')
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.login.error_on_logout'))
                }
            },
            getVariant() {
                if (this.$store.getters['messages/getUnreadMessagesCount'] > 0) {
                    return 'warning'
                } else {
                    return 'secondary'
                }
            },
            viewMessage(id) {
                this.$router.push({ name: 'system_message', params: { message_id: id } })
            }
        },
        mounted() {
            if (this.userData) {
                this.selected_client = this.userData.client_id

                this.getFursStatus()

                this.fursInterval = window.setInterval(
                    this.getFursStatus,
                    40000
                )
            }
        },
        beforeDestroy() {
            if (this.fursInterval) {
                clearInterval(this.fursInterval)
            }
        }
    }
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.noPaddingRight {
  padding-right: 0 !important;
}

.dropdown_line {
    border-bottom: 0.5px solid lightgrey !important;
}


.dark-layout .unreadMessage {
    background: #626262 !important;
    font-weight: bolder;
    color: #cccccc !important;
}

.light-layout .unreadMessage {
    background: #e3e3e3 !important;
    font-weight: bolder;
}

.notification-dot {
    position: absolute;
    top: -10px;
    right: -5px;
    height: 16px;
    width: 16px;
    background-color: #e8af44;
    border-radius: 50%;
    display: inline-block;
    color: white;
    font-size: 12px;
    text-align: center;
}

.dropdown_line {
    border-bottom: 0.5px solid lightgrey !important;
}


.dark-layout .unreadMessage {
    background: #626262 !important;
    font-weight: bolder;
    color: #cccccc !important;
}

.light-layout .unreadMessage {
    background: #e3e3e3 !important;
    font-weight: bolder;
}

.notification-dot {
    position: absolute;
    top: -10px;
    right: -5px;
    height: 16px;
    width: 16px;
    background-color: #e8af44;
    border-radius: 50%;
    display: inline-block;
    color: white;
    font-size: 12px;
    text-align: center;
}
.dropdown-user a, .dropdown-user .user-nav,
.bell-icon .nav-link{
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  //background-color: red !important;
  margin: 0 !important;
}
.square-button.btn {
  aspect-ratio: 1 !important;
  height: fit-content;
  max-height: 25px;
  width: 25px;
}

.nav-badge {
  color: rgba(34, 41, 47, 0.5);
}
.dark-layout .nav-badge {
  background: $theme-dark-body-bg;
  color: white;
}
</style>

<style lang="scss" scoped>
    .circle{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 0.3rem;
    }

    .green {
      background: green;
    }

</style>
