<template>
    <b-modal id="cashRegisterStatusModal" :title="$t('config.cash_registers.status.cash_registers_status')">
        <b-overlay :show="showLoading">
            <div class="d-flex justify-content-end mb-2">
                <b-badge v-if="isAnyCashRegisterOnline($NilObjectId)" variant="primary" @click="updateAllOnlineCashRegisters" style="cursor: pointer;">{{$t('config.cash_registers.status.update_all_cash_registers')}}</b-badge>
            </div>
            <div>
                <div v-for="(item, id) in cashRegisters" :key="'cashrtegisterstatus_businessUnit' + item.business_unit + '_' + id" class="mb-1">
                    <div class="d-flex justify-content-between">
                        <div block variant="primary" @click="selectedBusinessUnit(item.business_unit_id)" style="cursor: pointer">
                            <div>
                                <span v-if="selectedBusinessUnitId === item.business_unit_id" class="mr-1">
                                    <feather-icon icon="CornerLeftUpIcon" size="15" stroke-width="4" stroke="#b4bfb5"/>
                                </span>
                                <span v-else class="mr-1">
                                    <feather-icon icon="CornerDownRightIcon" size="15" stroke-width="4" stroke="#b4bfb5"/>
                                </span>
                                <span class="primary-text" style="font-size: 16px; font-weight: bold">
                                    {{item.business_unit_name}}
                                </span>
                            </div>
                        </div>
                        <div style="cursor: pointer" v-if="isAnyCashRegisterOnline(item.business_unit_id)" @click="updateAllOnlineCashRegistersInBusinessUnit(item.business_unit_id)">
                            <feather-icon variant="primary" icon="DownloadIcon" size="18" stroke-width="4"/>
                        </div>
                    </div>
                    <div v-if="selectedBusinessUnitId === item.business_unit_id" class="py-2 pl-2" style="margin-bottom: 5px;" :id="'accordion_business_unit_' + item.business_unit_id">
                        <div class="d-flex justify-content-between align-items-center" v-for="(cashRegister, id) in item.cash_registers" :key="'cashrtegisterstatus_' + item.business_unit + '_' +  cashRegister.cash_register_id + '_' + id">
                            <div class="d-flex align-items-center">
                                <div style="position: relative" :id="'circle_orange' + cashRegister.cash_register_id"
                                     @mouseover="tooltipEnter('circle_orange' + cashRegister.cash_register_id)"
                                     @mouseleave="tooltipLeave('circle_orange' + cashRegister.cash_register_id)">
                                    <b-spinner style="position: absolute; height: 20px; width: 20px;" v-if="updateInProgress && updateInProgress[cashRegister.cash_register_id] === 1" />
                                    <div class="circle gray" :id="'gray_circle' + cashRegister.cash_register_id" v-if="cashRegister.status === CashRegisterStatus.NoData && ((updateInProgress && updateInProgress[cashRegister.cash_register_id] !== 2) || !updateInProgress) && !(errorsCashRegisters && errorsCashRegisters[cashRegister.cash_register_id] && errorsCashRegisters[cashRegister.cash_register_id].length > 0)">
                                        <b-tooltip :title="$t('config.cash_registers.cash_register_status_unavailable')" triggers="hover" custom-class="tooltip-custom" placement="rightbottom" boundary="window" style="box-shadow: none !important;" :target="'gray_circle' + cashRegister.cash_register_id">{{$t('config.cash_registers.cash_register_status_unavailable')}}</b-tooltip>
                                    </div>
                                    <div class="circle green" v-else-if="cashRegister.status === CashRegisterStatus.Online && ((updateInProgress && updateInProgress[cashRegister.cash_register_id] !== 2) || !updateInProgress) && !(errorsCashRegisters && errorsCashRegisters[cashRegister.cash_register_id] && errorsCashRegisters[cashRegister.cash_register_id].length > 0)" />
                                    <div v-else-if="((updateInProgress && updateInProgress[cashRegister.cash_register_id] !== 2) || !updateInProgress) && !(errorsCashRegisters && errorsCashRegisters[cashRegister.cash_register_id] && errorsCashRegisters[cashRegister.cash_register_id].length > 0)" class="circle red"/>
                                    <div v-else class="circle orange">
                                        <b-tooltip style="width: 700px;" custom-class="tooltip-custom" placement="rightbottom" boundary="window" :target="'gray_circle_gray' + cashRegister.cash_register_id" triggers="hover" title="Online!">

                                            <div class="title">
                                                {{$t('config.cash_registers.status.missing_data')}}
                                            </div>
                                            <div class="p-1">
                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'WorkPremises')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.spaces')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'PrinterConfig')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.printer')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'Employees')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('menu.subcategory.employees')}}
                                                    </div>
                                                </div>


                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'PaymentTypes')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('menu.subcategory.payment_types')}}

                                                    </div>
                                                </div>


                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'VATGroups')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.taxes')}}
                                                    </div>
                                                </div>


                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'Warehouses')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('menu.subcategory.warehouses')}}
                                                    </div>
                                                </div>


                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'Employees')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('menu.subcategory.employees')}}
                                                    </div>
                                                </div>


                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'Products')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.products')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'Certificate')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.certificate')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'ProductDisplayGroups')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('general.display_groups')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'PriceLists')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('general.price_list')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'PriceListItems')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.product_price_list')}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center pb-1">
                                                    <span v-if="checkIfIncludes(cashRegister.cash_register_id, 'PriceListItems')">
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Checkmark.svg')" alt="Checkmark."/>
                                                    </span>
                                                    <span v-else>
                                                        <img style="width: 22px; height: 22px;" :src="require('@/assets/images/icons/Cross.svg')" alt="Cross."/>
                                                    </span>
                                                    <div class="px-2 text">
                                                        {{$t('config.cash_registers.status.cash_register_data')}}
                                                    </div>
                                                </div>
                                            </div>

                                        </b-tooltip>
                                    </div>
                                </div>
                                <span class="pl-1 primary-text" style="font-weight: bold; font-size: 18px;">{{cashRegister.on_premise_name}}</span>
                            </div>
                            <b-badge class="d-flex justify-content-center align-items-center" style="cursor:pointer;" @click="sendUpdateRequest(cashRegister.cash_register_id, true)" size="xs" variant="primary" v-if="cashRegister.status === CashRegisterStatus.Online">{{$t('general.update')}}</b-badge>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
        <template #modal-footer>
            <b-button variant="primary" @click="hideModal">
                <span>
                    <span class="align-middle">{{ $t('general.ok') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import {BModal, BBadge, BSpinner, BTooltip, BOverlay, BButton} from 'bootstrap-vue'
    import Vue from 'vue'
    import * as Sentry from '@sentry/vue'
    import {CashRegisterStatus} from '@/libs/enums/CashRegisterStatus'

    export default {
        computed: {
            CashRegisterStatus() {
                return CashRegisterStatus
            }
        },
        components:{
            BButton,
            BModal,
            BBadge,
            BSpinner,
            BTooltip,
            BOverlay
        },
        data() {
            return {
                cashRegisters: [],
                updateInProgress: {},
                errorsCashRegisters: {},
                selectedBusinessUnitId: '',
                showLoading: false,
                updating: false,
                current_cash_register_id:''
            }
        },
        methods: {
            isAnyCashRegisterOnline(businessUnitId) {
                for (const item of this.cashRegisters) {
                    if (item.business_unit_id === businessUnitId || businessUnitId === this.$NilObjectId) {
                        for (const cr of item.cash_registers) {
                            if (cr.status === CashRegisterStatus.Online) {
                                return true
                            }
                        }
                    }
                }

                return false
            },
            tooltipEnter(id) {
                this.$root.$emit('bv::show::tooltip', id)
            },
            tooltipLeave(id) {
                this.$root.$emit('bv::hide::tooltip', id)
            },
            hideModal() {
                this.$bvModal.hide('cashRegisterStatusModal')
            },
            checkIfIncludes(id, error) {
                if (this.errorsCashRegisters && this.errorsCashRegisters[id] && this.errorsCashRegisters[id].length > 0) {
                    const index = this.errorsCashRegisters[id].findIndex(ele => ele === error)

                    return index === -1
                }

                return false
            },
            selectedBusinessUnit(id) {
                if (this.selectedBusinessUnitId === id) {
                    this.selectedBusinessUnitId = ''
                } else {
                    this.selectedBusinessUnitId = id
                }
            },
            async updateAllOnlineCashRegistersInBusinessUnit(businessUnitId) {
                this.showLoading = true
                const success = []
                for (const item of this.cashRegisters) {
                    if (item.business_unit_id === businessUnitId) {
                        for (const cash_register of item.cash_registers) {
                            if (cash_register.status) {
                                success.push(await this.sendUpdateRequest(cash_register.cash_register_id))
                            }
                        }
                    }
                }
                if (success.includes(false)) {
                    if (success.length === success.filter(x => x === false).length) {
                        this.$printError(this.$t('print.error.on_cash_registers_update'))
                    } else {
                        this.$printError(this.$t('print.error.on_some_cash_registers_update'))
                    }
                } else if (success.length > 0) {
                    this.$printSuccess(this.$t('print.success.cash_registers_updated'))
                }
                this.showLoading = false
            },
            async updateAllOnlineCashRegisters() {
                if (this.updating) return
                this.showLoading = true
                const success = []
                for (const item of this.cashRegisters) {
                    for (const cash_register of item.cash_registers) {
                        if (cash_register.status) {
                            success.push(await this.sendUpdateRequest(cash_register.cash_register_id))
                        }
                    }
                }
                if (success.includes(false)) {
                    if (success.length === success.filter(x => x === false).length) {
                        this.$printError(this.$t('print.error.on_cash_registers_update'))
                    } else {
                        this.$printError(this.$t('print.error.on_some_cash_registers_update'))
                    }
                } else if (success.length > 0) {
                    this.$printSuccess(this.$t('print.success.cash_registers_updated'))
                }
                this.showLoading = false
            },
            async sendUpdateRequest(id, single = false) {
                if (this.updating && this.current_cash_register_id === id) return
                this.current_cash_register_id = id
                try {
                    // 0 -> nothing
                    // 1 -> in progress
                    // 2 -> error
                    // 3 -> success
                    this.updating = true
                    Vue.set(this.updateInProgress, id, 1)
                    const response = await this.$http.post(`/api/client/v1/cash_registers/${  id}`)
                    if (response && response.data) {
                        Vue.set(this.errorsCashRegisters, id, response.data.errors)
                    }
                    Vue.set(this.updateInProgress, id, 3)
                    if (single) this.$printSuccess(this.$t('print.success.cash_register_updated'))
                    this.updating = false
                    return true
                } catch (err) {
                    Sentry.captureException(err)
                    if (single) this.$printError(this.$t('print.error.on_update'))
                    Vue.set(this.updateInProgress, id, 2)
                    this.updating = false
                    return false
                }
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/client/v1/cash_registers/online_status')
                    const data = res.data ?? []

                    for (const i in data) {
                        data[i].cash_registers = data[i].cash_registers.sort(function compare(a, b) {
                            if (a.status === CashRegisterStatus.Online && b.status !== CashRegisterStatus.Online) {
                                return -1
                            } else if (a.status !== true && b.status === CashRegisterStatus.Online) {
                                return 1
                            } else {
                                return a.id - b.id
                            }
                        })
                        data[i].cash_registers = data[i].cash_registers.filter((obj) => obj.disabled === false)
                    }
                    this.cashRegisters = data
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_cash_registers_load'))
                }
            },
            open() {
                this.$bvModal.show('cashRegisterStatusModal')
                this.loadData()
            }
        }
    }

</script>

<style>
  .tooltip-custom .tooltip-inner{
    max-width: 700px;
    width: 100%;
    padding: 15px;
    /*border-style: solid;*/
    /*border-width: 1px;*/
    /*border-color: #D2D2CF;*/
    border-radius: 20px;
  }
</style>

<style lang="scss" scoped>
  .circle{
    height: 20px;
    width: 20px;
    border-radius: 10px;
  }

  .red{
    background: #ff6962;
  }

  .green{
    background: #abecb8;
  }

  .gray{
background: gray;
  }

  .orange{
    background: #ff964f;
  }
</style>